import { CheckCircleIcon } from '@heroicons/react/20/solid'

import i18next from '../../../i18n';
const { t } = i18next;

const includedFeatures = [
  t("generation_of_text_posts"),
  t("generation_of_image_posts"),
  t("schedule_posts_to_your_social_media"),
  t("customize_a_personalitty"),
  t("customize_the_mood_of_your_posts"),
  t("set_marketing_goals"),
  t("connect_to_your_social_media_accounts"),
  t("improve_the_quality_of_your_posts")
]


export default function Pricing() {
  return (
    <div className="bg-gray-100" id="pricing">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            {t("simple_no_tricks_pricing")}
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              {t("if_you_are_not_satisfied_contact_us_within_14")}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-10 lg:pb-10">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-100" />
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">{t("valuable_payments_for_best_service")}</h3>
                <p className="mt-6 text-base text-gray-500">
                  {t("when_you_purchase_a_member_ship_you_get_the_best_service")}
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">
                      {t("whats_included")}
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                <p className="text-lg font-medium leading-6 text-gray-900">{t("starts_from")}</p>
                <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                  <span>$ 9.99</span>
                  <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">USD</span>
                </div>
                {/* <p className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-500 underline">
                    Learn about our membership policy
                  </a>
                </p> */}
                {/* <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                    >
                      Buy now
                    </a>
                  </div>
                </div> 
                <div className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-900">
                    Get a free sample <span className="font-normal text-gray-500">(20MB)</span>
                  </a>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
