import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from "./pages/privacy/Privacy";
import Waitlist from "./pages/waitlist/Waitlist";

export default function App(){
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/waitlist" element={<Waitlist/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}