import { useState } from "react";
import i18next from "../../../i18n";
// import { update_user_language } from "../utils/api";

const { t } = i18next;

export default function LanguageSelector() {
    const current_language = window.localStorage.getItem('language');
    const [language, setLanguage] = useState(i18next.language || 'en');

    const handle_change_language = async (e) => {
        setLanguage(e.target.value);
        try {
            i18next.changeLanguage(e.target.value);
            // await update_user_language({language : i18next.language})
        } catch (e) {

        }

        // window.localStorage.setItem('language', e.target.value);
        window.location.reload();
    }


    return <div className="inline-block px-4">
        <div className="flex">
            <div className="flex-2 text-xs text-gray-600 py-2 px-1">
                {t("language_abbr")}
            </div>
            <div className="inline border rounded-md border-3 border-sky-200 px-3 py-2">
                <select value={language} onChange={handle_change_language} className="border-0 text-sm text-gray-600 focus:ring-0">
                    <option selected={language === "en"} value="en">English</option>
                    <option selected={language === "fr"} value="fr">Français</option>
                    <option selected={language === "sw"} value="sw">Kiswahili</option>
                    <option selected={language === "pt"} value="pt">Português</option>
                    <option selected={language === "es"} value="es">Español</option>
                    {/* <option selected={language === "cn"} value="cn">中文</option> */}
                    <option selected={language === "in"} value="in">हिन्दी</option>
                </select>
            </div>
        </div>
    </div>
}