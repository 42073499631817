import BrandPanel from "./components/BrandPanel";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Pricing from "./components/Pricing";
import Support from "./components/Support";
import TrustedBy from "./components/TrustedBy";

export default function Home(){
    return (
        <div>
            <Header/>
            <Support/>
            <BrandPanel/>
            <Pricing/>
            <TrustedBy/>
            <Footer/>
        </div>
    )
}